import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom/client' // Cambiar importación
import { HelmetProvider } from 'react-helmet-async'

import { Routes } from './routes/Routes'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'

import './styles/styles.scss'

// Crear root con React 18
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <StrictMode>
    <HelmetProvider>
      <Routes />
    </HelmetProvider>
  </StrictMode>
)

// Service worker
serviceWorkerRegistration.unregister()

// Medición de performance
reportWebVitals()
