import React from 'react'
const isMobile = window.innerWidth <= 768

import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, A11y } from 'swiper/modules'
import 'swiper/swiper-bundle.css'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

import './swiper.scss'

import imageDeskVerano2025 from '../../statics/images/Home/Banner-Home-DESKTOP-CRISTAL-VERANO-2025.png'
import imageMBVerano2025 from '../../statics/images/Home/Banner-HOME-MOBILE-CRISTAL-VERANO-2025_425X691.png'
import imageDeskVerano2024 from '../../statics/images/Home/Banner-Home-CRISTAL-VERANO-2024.jpg'
import imageTabletVerano2024 from '../../statics/images/Home/Banner-HOME-MOBILE-CRISTAL-VERANO-2024.jpg'
import imageMbVerano2024 from '../../statics/images/Home/Banner-HOME-MOBILE-CRISTAL-VERANO-2024_425X691.jpg'
import imageMbCopa2024 from '../../statics/images/Home/Banner-HOME-MOBILE-CRISTAL-COPA-2024_425X691-JUNIO.png'
import imageTabletCopa2024 from '../../statics/images/Home/Banner-HOME-MOBILE-CRISTAL-COPA-2024-JUNIO.png'
import imageDeskCopa2024 from '../../statics/images/Home/Banner-Home-DESKTOP-CRISTAL-COPA-2024-JUNIO.png'
import imageDeskFiestasPatrias2024 from '../../statics/images/Home/Banner-Home-desktop-Fiestas-Patrias-SELLO-CUADRADO.png'
import imageMBFiestasPatrias2024 from '../../statics/images/Home/Banner-Home-mobile-Fiestas-Patrias.png'
import imageDeskSed2024 from '../../statics/images/Home/Banner-Home-CRISTAL-Credenciales-2024.png'
import imageMBSed2024 from '../../statics/images/Home/Banner-HOME-MOBILE-CRISTAL-Credenciales-2024_425X691.png'

const ArraySlides = [
  {
    imageMobile: imageMBVerano2025,
    imageDesktop: imageDeskVerano2025,
  },
  {
    imageMobile: imageMBSed2024,
    imageDesktop: imageDeskSed2024,
  },
  {
    imageMobile: imageMBFiestasPatrias2024,
    imageDesktop: imageDeskFiestasPatrias2024,
  },
  {
    imageMobile: imageMbCopa2024,
    imageTable: imageTabletCopa2024,
    imageDesktop: imageDeskCopa2024,
    url: 'https://concurso.cristal.cl',
  },
  {
    imageMobile: imageMbVerano2024,
    imageTable: imageTabletVerano2024,
    imageDesktop: imageDeskVerano2024,
  },
  /* {
    Ejemplo
    imageMobile: imageMobile1,
    imageTable: '',
    imageDesktop: imageDesktop1,
    title: 'Juntémonos',
    subtitle: 'con una Cristal, RErefrescante.',
  } */
]

export const SwiperGallery = () => {
  return (
    <Swiper
      className="Hero__swiper"
      spaceBetween={0}
      slidesPerView={1}
      modules={[Pagination, A11y]}
      pagination={{ clickable: true }}
    >
      {ArraySlides.map((item, i) => {
        return (
          <SwiperSlide
            key={i}
            style={{
              backgroundImage: `url(${
                isMobile ? item.imageMobile : item.imageDesktop
              })`,
            }}
          >
            <a href={item.url} target="_blank" rel="noopener noreferrer">
              <div className="Hero__swiper__content">
                <div className="Swiper__content__text">
                  <h1>{item.title}</h1>
                  <h2>{item.subtitle}</h2>
                  <p>{item.text}</p>
                </div>
              </div>
            </a>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}
